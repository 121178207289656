import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../models";

const initialState: User = {
    email: "",
    id: 0,
    firstName: "",
    lastName: "",
    dateCreated: "",
    isSuperAdmin: false,
    token: "",
    imgUrl: "",
    organizationAccessGroup: []
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        editEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload
        },
        swapUser: (state, action: PayloadAction<User>) => {
            state.organizationAccessGroup = action.payload.organizationAccessGroup
            state.id = action.payload.id
            state.isSuperAdmin = action.payload.isSuperAdmin
            state.token = action.payload.token
            state.email = action.payload.email
            state.imgUrl = action.payload.imgUrl
        },
        clearUser: () => {
            return initialState;
        },
    }
})

export const { editEmail, swapUser, clearUser } = userSlice.actions

export default userSlice.reducer;
