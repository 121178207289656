import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

type Props = {
  viewers: number;
}

export function LiveBadge({viewers}: Props) {
  return (
    <span className='badge-live'>
      <FontAwesomeIcon icon={regular('eye')} />
      <span>LIVE</span>
      <span className='viewer-count'>{viewers}</span>
    </span>
  );
}
