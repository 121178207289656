import React from "react";
import { Provider } from "react-redux";
import "./App.scss";
import { Main } from "./screens";
import { QueryClient, QueryClientProvider } from "react-query";
import { AbilityContext } from "./components/Can";
import { buildAbilityFor } from "./utils/ability";
import { v4 as uuidv4 } from "uuid";
import { store, useAppSelector } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { HelmetProvider } from "react-helmet-async";
import { createTheme, ThemeProvider } from "@mui/material/styles";

if (process.env.NODE_ENV === "development")
  console.log("environment", process.env);

const queryClient = new QueryClient();
let persistor = persistStore(store);

const rootHTML = document.documentElement;

const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#001731",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#0c60c7",
      //light: '#F5EBFF',
      // dark: will be calculated from palette.secondary.main,
      //contrastText: '#47008F',
    },
  },
});

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#F5EBFF",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#47008F",
      //light: '#F5EBFF',
      // dark: will be calculated from palette.secondary.main,
      //contrastText: '#47008F',
    },
  },
});

const AppWrapper = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};

function App() {
  const { user, settings } = useAppSelector((state) => state);
  const ability = buildAbilityFor(user);
  localStorage.setItem("session", uuidv4());
  localStorage.setItem("token", user.token);
  rootHTML?.setAttribute("data-theme", settings.isDarkTheme ? "dark" : "light");
  return (
    <ThemeProvider theme={settings.isDarkTheme ? darkTheme : lightTheme}>
      <AbilityContext.Provider value={ability}>
        <QueryClientProvider client={queryClient}>
          <Main />
        </QueryClientProvider>
      </AbilityContext.Provider>
    </ThemeProvider>
  );
}

export default AppWrapper;
