import { useContext, useState, useCallback } from "react";
import { AbilityContext } from "./Can";
import { userLogin } from "../api/users";
import { useAppDispatch } from "../store";
import { swapUser } from "../store/slices/users";
import { toggleLoginMenu, toggleSignUp } from "../store/slices/settings";
import { updateAbility } from "../utils/ability";
import { User } from "../models";
import BarLoader from "react-spinners/BarLoader";

export function LoginControls() {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const ability = useContext(AbilityContext);
  const dispatch = useAppDispatch();

  const handleClick = async () => {
    if (username.length < 1) {
      setErrorMessage("Please enter a username.");
      return true;
    }

    if (password.length < 1) {
      setErrorMessage("Please enter a password.");
      return true;
    }

    setIsLoading(true);
    try {
      const data: User = await userLogin({ username, password });
      if (data) {
        updateAbility(ability, data);
        dispatch(swapUser(data));
        setUserName("");
      } else {
        //-- add error handling
      }
      setIsLoading(false);
      setPassword("");
      dispatch(toggleLoginMenu());
    } catch (exception: any) {
      console.log(exception);
      if (exception && exception.response && exception.response.status === 400) {
        setErrorMessage("User name and password do not match any accounts.");
      } else {
        setErrorMessage("Sorry, something went wrong. Try again later.");
      }
      setIsLoading(false);
      return true;
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  const toggleSignUpClick = useCallback(() => {
    dispatch(toggleSignUp());
  }, [dispatch]);

  return (
    <div className="login-wrapper">
      <h1>Log In</h1>
      <form onSubmit={handleClick}>
        <label>
          <p>Username</p>
          <input
            type="text"
            autoComplete="Off"
            value={username}
            onChange={(e) => setUserName(e.target.value)}
            onKeyDown={(e) => onKeyDown(e)}
          />
        </label>
        <label>
          <p>Password</p>
          <input
            type="password"
            autoComplete="Off"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => onKeyDown(e)}
          />
        </label>
        <br />
        <br />
        <div className="flex-box flex-wrap">
          <div className="button" onClick={handleClick}>
            Submit
          </div>
          <div className="button-link" onClick={toggleSignUpClick}>
            Sign up
          </div>
          {isLoading ? (
            <div className="loader-container">
              <BarLoader className="loader" />
            </div>
          ) : null}
          {errorMessage ? (
            <div className="flex-box flex-wrap error-message">
              {errorMessage}
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
}
