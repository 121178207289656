import { useEffect, useState } from 'react';
import { ReactPlayerWrapper, LiveBadge } from '../components';
import { useParams } from "react-router-dom";
import { useStreamInfo, useStreamDetails } from "../api/video";
import { videoTitle, streamDateTitle, streamViewerCount } from "../utils/videos";
import { DiscussionEmbed } from 'disqus-react';
import Can from '../components/Can';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { postStream } from '../api/video';

export function Video() {
  const { streamUrlKey } = useParams();
  const [title, setTitle] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [description] = useState('');
  var { data: streamInfo, refetch: refetchStreamInfo } = useStreamInfo(streamUrlKey ?? '');
  const { data: streamDetails } = useStreamDetails(streamUrlKey ?? '');
  const [streamUrl, setStreamUrl] = useState(streamInfo ? streamInfo.streamUrl : undefined);
  useEffect(() => {
    if (streamInfo && streamInfo.streamUrl !== streamUrl) {
      setStreamUrl(streamInfo.streamUrl); // only update stream URL when changed.
    }
  }, [streamInfo, streamUrl]);

  const handleEditClick = (streamInfo: any) => {
    if (streamInfo) setTitle(videoTitle(streamInfo));
    setIsEditMode(true);
  }

  const handleCancelClick = () => {
    setIsEditMode(false);
  }

  const handleSaveClick = async () => {
    var request = {
      ...streamInfo,
      description,
      title
    }
    const data: any = await postStream(request);
    if (data && !data.Error) {
      await refetchStreamInfo();
      setIsEditMode(false);
    } else {
      //-- add error handling
    }
  }

  return (
    <div>
      {streamInfo ?
        <Helmet>
          <title>{videoTitle(streamInfo)}</title>
        </Helmet> : null
      }
      <div className='video-wrapper'>
        <ReactPlayerWrapper
          url={streamUrl}
        />
        <div>
          {streamInfo ?
            <div>
              <h1 className='video-title-h1'>
                {
                  !isEditMode ?
                    videoTitle(streamInfo) :
                    <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                }
                {streamInfo && streamInfo.isLive && streamDetails ? <LiveBadge viewers={streamDetails.activeViewers} /> : null}
              </h1>
              <div className="video-details-row-container">
                <div className="video-details-container">
                  {streamDetails ? <span className="video-title-author">{streamInfo.channelName}</span> : null}
                  {streamDetails ? <span className="video-details">{streamViewerCount(streamDetails.totalViews)} views | {streamDateTitle(streamInfo)}</span> : null}
                </div>
                <div className="video-actions-panel">
                  <button className="hide">
                    <FontAwesomeIcon icon={solid('thumbs-up')} />
                    <span>2k</span>
                  </button>
                  <button className="hide">
                    <FontAwesomeIcon icon={regular('thumbs-down')} />
                    <span>1k</span>
                  </button>
                  <button className="hide">
                    <FontAwesomeIcon icon={regular('share-from-square')} />
                    <span>Share</span>
                  </button>
                  <Can I="manage" this={streamInfo}>
                    {
                      !isEditMode ?
                        <button onClick={() => handleEditClick(streamInfo)}>
                          <FontAwesomeIcon icon={regular('pen-to-square')} />
                          <span>Edit</span>
                        </button> :
                        <div>
                          <button onClick={handleSaveClick}>
                            <FontAwesomeIcon icon={regular('save')} />
                            <span>Save</span>
                          </button>
                          <button onClick={handleCancelClick}>
                            <FontAwesomeIcon icon={solid('xmark')} />
                            <span>Cancel</span>
                          </button>
                        </div>
                    }
                  </Can>
                </div>
              </div>
              <DiscussionEmbed
                shortname='godresource'
                config={
                  {
                    url: `https://new.godresource.com/video/${streamUrlKey}`,
                    identifier: streamUrlKey,
                    title: videoTitle(streamInfo)
                  }
                }
              />
            </div>
            : null
          }
        </div>
      </div>
    </div>
  );
}
