import { useCallback } from "react";
import { Link } from "react-router-dom";
import { GoSearch, GoPlay } from "react-icons/go";
import { useAppSelector, useAppDispatch } from "../store";
import { GiPublicSpeaker } from "react-icons/gi";
import { TbBuildingChurch } from "react-icons/tb";
import { BiBible } from "react-icons/bi";
import { toggleMainMenu, toggleBiblePopOut } from "../store/slices/settings";

export function Navbar() {
  const settings = useAppSelector((state: any) => state.settings);
  const dispatch = useAppDispatch();

  const closeMainMenu = useCallback(() => {
    dispatch(toggleMainMenu(false));
    dispatch(toggleBiblePopOut(false));
  }, [dispatch]);
  
  return (
    <div className={"navbar" + (settings.mainMenuOpen ? "" : " navbar-close")}>
      <ul>
        <li>
          <Link onClick={closeMainMenu} to="/video">
            <div className="navbar-icon">
              <GoPlay size={24} />
            </div>
            <span className="navbar-title">Video</span>
          </Link>
        </li>
        <li className="hide">
          <Link onClick={closeMainMenu} to="/bible">
            <div className="navbar-icon">
              <BiBible size={24} />
            </div>
            <span className="navbar-title">Bible</span>
          </Link>
        </li>
        <li>
          <Link onClick={closeMainMenu} to="/bible/search">
            <div className="navbar-icon">
              <GoSearch size={24} />
            </div>
            <span className="navbar-title">Bible Search</span>
          </Link>
        </li>
        <li className="hide">
          <Link onClick={closeMainMenu} to="/entirebiblepreached">
            <div className="navbar-icon">
              <GiPublicSpeaker size={24} />
            </div>
            <span className="navbar-title">Entire Bible Preached</span>
          </Link>
        </li>
        <li className="hide">
          <Link onClick={closeMainMenu} to="/churches">
            <div className="navbar-icon">
              <TbBuildingChurch size={24} />
            </div>
            <span className="navbar-title">Churches</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}
