import { useAppSelector } from "../store";
import { useUserAccount } from "../api/users";
import { User } from "../models";
import { Helmet } from "react-helmet-async";

export function Organizations() {
  const account: User = useAppSelector((state: any) => state.user);
  const { data: user } = useUserAccount(account.id);
  
  console.log('user?.organizationAccessGroup', user?.organizationAccessGroup)
  return (
    <div>
      <Helmet>
        <title>Organizations</title>
      </Helmet>
      <h1>Your Organizations</h1>
      {user?.organizationAccessGroup?.map((o, i) => (
         <div key={o.organizationId}>{o.organizationName}</div>)
        )}
    </div>
  );
}
