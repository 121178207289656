import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormOptions } from "../../models";

const initialState = {
  loginMenuOpen: false,
  mainMenuOpen: false,
  biblePopOut: false,
  profileMenuOpen: false,
  signUpToggle: false,
  contentScrollTop: 0,
  isDarkTheme: false,
  formPopOut: false,
  formAction: "",
  formId: 0,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setScrollTop: (state, action: PayloadAction<number | undefined>) => {
      state.contentScrollTop = action.payload ?? 0;
    },
    toggleLoginMenu: (state, action: PayloadAction<boolean | undefined>) => {
      state.loginMenuOpen =
        action.payload !== undefined ? action.payload : !state.loginMenuOpen;
      state.signUpToggle = state.loginMenuOpen ? false : state.signUpToggle;
    },
    toggleProfileMenu: (state, action: PayloadAction<boolean | undefined>) => {
      state.profileMenuOpen =
        action.payload !== undefined ? action.payload : !state.profileMenuOpen;
    },
    toggleMainMenu: (state, action: PayloadAction<boolean | undefined>) => {
      state.mainMenuOpen =
        action.payload !== undefined ? action.payload : !state.mainMenuOpen;
      state.loginMenuOpen = false;
      state.profileMenuOpen = false;
    },
    toggleSignUp: (state, action: PayloadAction<boolean | undefined>) => {
      state.signUpToggle =
        action.payload !== undefined ? action.payload : !state.signUpToggle;
    },
    toggleBiblePopOut: (state, action: PayloadAction<boolean | undefined>) => {
      state.biblePopOut =
        action.payload !== undefined ? action.payload : !state.biblePopOut;
    },
    toggleFormPopOut: (
      state,
      action: PayloadAction<FormOptions | undefined>
    ) => {
      state.formPopOut =
        action.payload !== undefined ? action.payload.state : false;
      state.formAction =
        action.payload !== undefined ? action.payload.action : "";
      state.formId = action.payload !== undefined ? action.payload.id : 0;
    },
    toggleTheme: (state, action: PayloadAction<boolean | undefined>) => {
      state.isDarkTheme =
        action.payload !== undefined ? action.payload : !state.isDarkTheme;
    },
  },
});

export const {
  setScrollTop,
  toggleLoginMenu,
  toggleProfileMenu,
  toggleMainMenu,
  toggleSignUp,
  toggleBiblePopOut,
  toggleFormPopOut,
  toggleTheme,
} = settingsSlice.actions;

export default settingsSlice.reducer;
