import { useRef, useEffect } from "react";
import { useBibleLoad } from "../api/bible";
import { useAppDispatch } from "../store";
import { toggleBiblePopOut } from "../store/slices/settings";
import { BibleComponentProps } from "../models";
import { IoCloseCircleSharp } from "react-icons/io5";
import BarLoader from "react-spinners/BarLoader";

export function BibleComponent({
  version,
  chapter,
  bookId,
  chapterId,
  verseId,
}: BibleComponentProps) {
  const myRef = useRef<null | HTMLElement>(null);
  const executeScroll = () => myRef?.current?.scrollIntoView();
  useEffect(() => {
    executeScroll();
  });
  const { data } = useBibleLoad({
    version,
    chapter,
    bookId,
    chapterId,
    verseId,
  });
  const dispatch = useAppDispatch();
  const handleCloseClick = () => {
    dispatch(toggleBiblePopOut(false));
  };

  const verses = data
    ? data
    : Array(30).fill({
        isDefault: true,
      });

  return (
    <div className="bible-popout-container">
      <div>
        <div className="bible-popout-header">
          <h1>
            {chapter} {chapterId}:{verseId}
          </h1>
          <span
            className="bible-popout-close-button"
            onClick={handleCloseClick}
          >
            <IoCloseCircleSharp size={30} />
          </span>
        </div>
        <br />
        <div className="bible-popout-verse-container">
          {verses ? (
            verses.map((verse: any, i: number) => (
              <BibleVerseResult
                key={i}
                verse={verse}
                verseId={verseId}
                chapterId={chapterId}
                bookId={bookId}
                myRef={myRef}
              />
            ))
          ) : (
            <BarLoader className="loader" />
          )}
        </div>
      </div>
    </div>
  );
}

function BibleVerseResult({
  verse,
  verseId,
  chapterId,
  bookId,
  myRef,
}: {
  verse: any;
  verseId: any;
  chapterId: any;
  bookId: any;
  myRef: any;
}) {
  const isSelectedVerse =
    verseId === verse.verseID &&
    chapterId === verse.chapterID &&
    bookId === verse.bookID;
  const specialTextArray = /<(.+)>/.exec(verse.verseText);
  let specialText =
    specialTextArray && specialTextArray.length > 1
      ? specialTextArray[1]
      : null;
  if (specialText) specialText = specialText.replace(".", "");
  //const specialTextStripped = specialText ? specialText.replace(/<>/, '') : null;
  let versePure = verse.verseText;
  if(versePure) versePure = versePure.replace(/<.+>/, "");
  return (
    <p>
      {specialText != null ? (
        <p className="special-text">{specialText}</p>
      ) : null}
      <span
        ref={isSelectedVerse ? myRef : null}
        className={
          "bible-verse" +
          (isSelectedVerse ? " bible-verse-highlight" : "") +
          (verse.isDefault ? " bible-verse-default" : "")
        }
      >
        <b className="verse-bold">{verse.verseID} </b>
        {versePure}
      </span>
    </p>
  );
}
