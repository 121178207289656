import axios from "axios";
import { useQuery } from "react-query";
import { apiBase } from "./shared";
import { Stream } from "../models";
import { getConfig } from "./config";

export async function postStream(stream: Stream) {
  const { data } = await axios.post(
    `${apiBase}Streams/${stream.streamUrlKey}`,
    {
      streamId: stream.streamId,
      title: stream.title,
      description: stream.description
    }, getConfig(stream.organizationId, stream.channelId)
  );
  return data;
}

async function getLatestStreamInfo(channelName: string) {
  const { data } = await axios.get(
    `${apiBase}Streams/Latest?channelName=${encodeURIComponent(channelName)}?isEmbed=false`
  );
  return data;
}

export function useLatestStreamInfo(channelName: string) {
  return useQuery(['latestStreamInfo', channelName], () => getLatestStreamInfo(channelName), {
    enabled: !!channelName,
    refetchInterval: 20000,
    refetchIntervalInBackground: true,
  });
}

async function getStreamDetails(streamUrlKey: string) {

  const { data } = await axios.get(
    `${apiBase}Streams/${encodeURI(streamUrlKey)}/Details?isEmbed=false`,
    getConfig(0, 0));
  return data;
}

async function getStreamInfo(streamUrlKey: string) {
  const { data } = await axios.get(
    `${apiBase}Streams/${encodeURI(streamUrlKey)}`, getConfig(0, 0)
  );
  return data;
}

export function useStreamInfo(streamUrlKey: string) {
  return useQuery(['streamInfo', streamUrlKey], () => getStreamInfo(streamUrlKey), {
    enabled: !!streamUrlKey,
    refetchIntervalInBackground: false,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}

export function useStreamDetails(streamUrlKey: string) {
  return useQuery(['streamDetails', streamUrlKey], () => getStreamDetails(streamUrlKey), {
    enabled: !!streamUrlKey,
    refetchInterval: 30000,
    refetchIntervalInBackground: true,
  });
}
