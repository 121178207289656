import { Stream as StreamModel } from "../models";

export function videoTitle(stream: StreamModel) {
  if (stream.title) {
    return stream.title;
  }
  const created = new Date(stream?.streamDateCreated || new Date());
  const hours = created.getHours();
  const ampm = hours > 11 ? 'pm' : 'am';
  let convertedHours = hours > 12 ? hours - 12 : hours;
  if (convertedHours === 0) {
    convertedHours = 12;
  }
  return `Stream started at ${leadingZero(created.getMonth() + 1)}/${leadingZero(created.getDate())}/${created.getFullYear()} ${convertedHours}:${leadingZero(created.getMinutes())}${ampm}`;
}

export function streamDateTitle(stream: StreamModel) {
  const created = new Date(stream?.streamDateCreated || new Date());
  const currentTime = new Date();
  return ago(created, currentTime);
}

export function streamViewerCount(viewString: string) {
  if (viewString === null) {
    return '';
  }
  const views = parseInt(viewString, 10);
  if (views >= 1_000_000) {
    const millions = views / 1_000_000;
    return `${millions.toFixed(1)}m`;
  }
  if (views >= 1_000) {
    const thousands = views / 1_000;
    return `${thousands.toFixed(1)}k`;
  }
  return views;
}

function leadingZero(n: number) {
  const str = `0${n.toString()}`;
  return str.substring(str.length - 2);
}

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const YEAR = 365 * DAY;

const STEPS: [number, string, string][] = [
  [YEAR, 'year', 'years'],
  [WEEK, 'week', 'weeks'],
  [DAY, 'day', 'days'],
  [HOUR, 'hour', 'hours'],
  [MINUTE, 'minute', 'minutes'],
  [SECOND, 'second', 'seconds'],
]

function ago(date: Date, now: Date): string {
  const diff = now.getTime() - date.getTime();

  for (const [step, singularStr, pluralStr] of STEPS) {
    if (diff >= step) {
      const value = Math.floor(diff / step);
      return `${plural(value, singularStr, pluralStr)} ago`
    }
  }
  return 'moments ago';
}

function plural(value: number, singularStr: string, pluralStr: string): string {
  return `${value} ${value === 1 ? singularStr : pluralStr}`;
}
