import Content from "./Content";
import { Header, Navbar } from "../screens";
import { BibleComponent } from "../components";
import { useAppSelector } from "../store";

export function Main() {
  const state = useAppSelector((state: any) => state);
  return (
    <div className="App" >
      <Navbar />
      <Header />
      <Content />
      <div
        className={
          "bible-popout" +
          (state.settings.biblePopOut ? " " : " bible-popout-close")
        }
      >
        <div className="bible-popout-inner">
          <BibleComponent {...state.bible} />
        </div>
      </div>
    </div>
  );
}
