import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BibleComponentProps } from "../../models";

const initialState: BibleComponentProps = {
  version: "kjv",
  chapter: "",
  bookId: 0,
  chapterId: 0,
  verseId: 0,
};

export const bibleSlice = createSlice({
  name: "bible",
  initialState,
  reducers: {
    setBible: (state, action: PayloadAction<BibleComponentProps>) => {
      state.version = action.payload.version;
      state.chapter = action.payload.chapter;
      state.bookId = action.payload.bookId;
      state.chapterId = action.payload.chapterId;
      state.verseId = action.payload.verseId;
    },
  },
});

export const { setBible } = bibleSlice.actions;

export default bibleSlice.reducer;
