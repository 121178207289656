import { useState } from "react";
import { useAppSelector, useAppDispatch } from "../store";
import { toggleFormPopOut } from "../store/slices/settings";
import { useGetAllUsers } from "../api/users";
import { User } from "../models";
import { UserEdit, UserView } from "../views";
import { TableUsers } from "../components";
import { Helmet } from "react-helmet-async";
import { IoCloseCircleSharp } from "react-icons/io5";

const options = {
  showSuperAdmin: true,
  isEditable: true,
  isViewable: false
};

export function Users() {
  const dispatch = useAppDispatch();
  const { settings } = useAppSelector((state: any) => state);
  const [data, setData] = useState<User[]>(() => []);
  const [loaded, setLoaded] = useState(false);
  const { data: allUsers, isLoading } = useGetAllUsers();
  if (allUsers && allUsers?.length > 0 && !isLoading && !loaded) {
    setData(allUsers);
    setLoaded(true);
  }

  const handleCloseClick = () => {
    dispatch(toggleFormPopOut());
  };

  return (
    <div>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <h1>Users</h1>
      <TableUsers data={data} isLoading={isLoading} options={options} />
      <div
        className={`form-popout ${
          !settings.formPopOut ? "form-popout-close" : ""
        }`}
      >
        <div className="form-popout-inner">
        <span className="bible-popout-close-button" onClick={handleCloseClick}>
          <IoCloseCircleSharp size={30} />
        </span>
        { settings.formAction === "edit" ?  <UserEdit id={settings.formId} /> : <UserView id={settings.formId} /> }
        </div>
      </div>
    </div>
  );
}
