import { GoThreeBars } from "react-icons/go";
import { CgProfile } from "react-icons/cg";
import { Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../store";
import { HiOutlineSearch } from "react-icons/hi";
import { Login, Profile, ProfileMenu } from "../components";
import {
  toggleLoginMenu,
  toggleMainMenu,
  toggleProfileMenu,
} from "../store/slices/settings";

const logo = require("../assets/images/gr-name.png");
const logoDark = require("../assets/images/gr-name-white.png");

export function Header() {
  const user = useAppSelector((state: any) => state.user);
  const settings = useAppSelector((state: any) => state.settings);
  const dispatch = useAppDispatch();

  return (
    <div className="App-header-container">
      <div className="App-header">
        <GoThreeBars
          size={24}
          onClick={() => dispatch(toggleMainMenu())}
          className="App-menu"
        />
        <Link to="/" onClick={() => dispatch(toggleMainMenu(false))}>
          <img src={settings.isDarkTheme ? logoDark : logo} alt="logo" className="App-logo" />
        </Link>
        <div className="App-menu-search-bar-container">
          <input className="search-bar" placeholder="search" />
          <HiOutlineSearch size={24} className="search-bar-icon" />
        </div>
        <div className="App-menu-login">
          {user.token !== "" ? (
            <div
              className="App-menu-profile"
              onClick={() => dispatch(toggleProfileMenu())}
            >
              <Profile imgUrl={user.imgUrl} />
            </div>
          ) : (
            <div onClick={() => dispatch(toggleLoginMenu())}>
              <CgProfile size={24} className="App-menu" />
            </div>
          )}
        </div>
      </div>
      <div
        className={
          "App-menu-login-screen" +
          (settings.loginMenuOpen ? "" : " login-screen-close")
        }
      >
        <Login />
      </div>
      <div
        className={
          "App-menu-profile-screen" +
          (settings.profileMenuOpen ? "" : " profile-screen-close")
        }
      >
        <ProfileMenu user={user} />
      </div>
    </div>
  );
}
