import moment from "moment";
import BarLoader from "react-spinners/BarLoader";
import { useAppDispatch } from "../store";
import { toggleFormPopOut } from "../store/slices/settings";
import { Button } from "@mui/material";
import { User, FormOptions } from "../models";
import { Table } from "./Table";
import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import { IoCheckmarkCircleSharp, IoRadioButtonOffSharp } from "react-icons/io5";
import { Profile } from "../components";

type Props = {
  data: User[];
  isLoading: boolean;
  options?: {
    showSuperAdmin: boolean;
    isEditable: boolean;
    isViewable: boolean;
  };
};

export function TableUsers({ data, isLoading, options }: Props) {
  const dispatch = useAppDispatch();

  const handleActionClick = async (id: number, action: string) => {
    const opts: FormOptions = {
      state: true,
      action,
      id,
    };
    dispatch(toggleFormPopOut(opts));
  };

  const columnHelper = createColumnHelper<User>();

  const columns: ColumnDef<User>[] = [
    columnHelper.accessor<"id", number>("id", {
      cell: (props) => <div className="td">{props.getValue()}</div>,
      header: () => <span>Id</span>,
      meta:  {  headerClassName: "hidden-mobile", cellClassName: "hidden-mobile" }
    }),
    columnHelper.accessor<"imgUrl", string>("imgUrl", {
      cell: (props) => (
        <div className="td profile-pic pic-small">
          <Profile imgUrl={props.getValue()} />
        </div>
      ),
      header: () => <span>Avatar</span>
    }),
    columnHelper.accessor<"firstName", string>("firstName", {
      cell: (props) => <div className="td">{props.getValue()}</div>,
      header: () => <span>First Name</span>,
    }),
    columnHelper.accessor<"lastName", string>("lastName", {
      cell: (props) => <div className="td">{props.getValue()}</div>,
      header: () => <span>Last Name</span>,
    }),
    columnHelper.accessor<"email", string>("email", {
      cell: (props) => <div className="td">{props.getValue()}</div>,
      header: () => <span>Email</span>,
      meta:  {  headerClassName: "hidden-mobile", cellClassName: "hidden-mobile" }
    }),
    columnHelper.accessor<"dateCreated", string>("dateCreated", {
      cell: (props) => (
        <div className="td">
          {moment(new Date(props.getValue())).format("MM/DD/YY")}
        </div>
      ),
      header: () => <span>Created</span>,
      meta:  {  headerClassName: "hidden-mobile", cellClassName: "hidden-mobile" }
    }),
  ] as ColumnDef<User>[];

  const editColumn = columnHelper.accessor<"id", number>("id", {
    id: "edit",
    cell: (props) => (
      <div className="td">
        <Button
          variant="outlined"
          onClick={() => handleActionClick(props.getValue(), "edit")}
        >
          Edit
        </Button>
      </div>
    ),
    header: () => <span></span>,
  }) as ColumnDef<User>;

  const displayColumn = columnHelper.accessor<"id", number>("id", {
    id: "display",
    cell: (props) => (
      <div className="td">
        <Button
          variant="outlined"
          onClick={() => handleActionClick(props.getValue(), "display")}
        >
          View
        </Button>
      </div>
    ),
    header: () => <span></span>,
  }) as ColumnDef<User>;

  const superAdminColumn = columnHelper.accessor<"isSuperAdmin", boolean>(
    "isSuperAdmin",
    {
      cell: (props) =>
        {
          return props.getValue() ? (
            <div className="td super-admin super-admin-checked">
              <IoCheckmarkCircleSharp />
            </div>
          ) : (
            <div className="td super-admin">
              <IoRadioButtonOffSharp />
            </div>
          );
        },
      header: (props) => <span>Super Admin</span>,
    }
  ) as ColumnDef<User>;

  if (options) {
    if (options.showSuperAdmin) columns.push(superAdminColumn);
    if (options.isViewable) columns.push(displayColumn);
    if (options.isEditable) columns.push(editColumn);
  }

  return (data && data?.length <= 0) || isLoading ? (
    <div className="loader-container">
      <BarLoader className="loader" />
    </div>
  ) : (
    <Table data={data} columns={columns} className="table-users" />
  );
}
