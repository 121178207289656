import { useCallback, useRef } from "react";
import { DebounceInput } from "react-debounce-input";
import { useSearchParams } from "react-router-dom";
import { BibleComponentProps, BibleVerse } from "../models";
import { useBibleSearch } from "../api/search";
import { Helmet } from "react-helmet-async";
import BarLoader from "react-spinners/BarLoader";
import { setBible } from "../store/slices/bible";
import { toggleBiblePopOut } from "../store/slices/settings";
import { useAppSelector, useAppDispatch } from "../store";
import { FaArrowAltCircleUp } from "react-icons/fa";

export function BibleSearch() {
  const settings = useAppSelector((state: any) => state.settings);
  let [searchParams, setSearchParams] = useSearchParams();
  const searchText = searchParams.get("text") ?? "";
  const setSearchText = useCallback(
    (newSearchText: string) => {
      setSearchParams(
        {
          ...searchParams,
          text: newSearchText,
        },
        {
          replace: true,
        }
      );
    },
    [searchParams, setSearchParams]
  );

  const { data, isLoading, isError } = useBibleSearch(searchText);
  const { verses, error , totalVerses } = data || {};

  if (error && error.message) console.log("error message:", error?.message);
  if (error && error.innerException)
    console.log("error innerException:", error?.innerException);

  const myRef = useRef<null | HTMLDivElement>(null);
  const executeScroll = () => 
    myRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });

  return (
    <div ref={myRef} className="bible-search-container">
      <div className="main-search-container">
        <Helmet>
          <title>GodResource Bible Search: {searchText}</title>
        </Helmet>
        <div className="main-search-header">
          <h1>Bible Search</h1>
          <DebounceInput
            autoFocus
            className="main-search"
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            debounceTimeout={350}
            placeholder="search"
          />
          {isError ? <p>Something isn't working...</p> : null}
          {error && error.message ? (
            <p className="error-message">
              Oops, something went wrong. Please try again later.
            </p>
          ) : null}
        </div>
        {isLoading ? (
          <div className="loader-container">
            <BarLoader className="loader" />
          </div>
        ) : null}
        {verses ? (
          <SearchResults
          totalVerses={totalVerses}
            searchText={searchText}
            verses={verses}
            isLoading={isLoading}
          />
        ) : null}
      </div>
      {settings.contentScrollTop > 200 ? (
        <span className="scroll-arrow" onClick={executeScroll}>
          <FaArrowAltCircleUp size={40} />
        </span>
      ) : null}
    </div>
  );
}

function SearchResults({
  totalVerses,
  searchText,
  verses,
  isLoading,
}: {
  totalVerses: number | undefined;
  searchText: string;
  verses: BibleVerse[];
  isLoading: boolean;
}) {
  return (
    <div className="main-search-results">
      <ul>
        {totalVerses && totalVerses > 0 ? (
          <div>
            <span>{totalVerses.toLocaleString()} verses matched your search. Showing {verses.length.toLocaleString()} verses.</span>
            {verses.map((verse: any, i: number) => (
              <SearchResultsVerse key={i} verse={verse} />
            ))}
          </div>
        ) : searchText ? (
          <li>no results</li>
        ) : (
          <li>please enter search criterion</li>
        )}
      </ul>
    </div>
  );
}

function SearchResultsVerse({ verse }: { verse: any }) {
  const dispatch = useAppDispatch();

  const handleVerseHeaderClick = (verse: any) => {
    const b: BibleComponentProps = {
      version: "kjv",
      chapter: verse.bookName,
      bookId: verse.bookID,
      chapterId: verse.chapterID,
      verseId: verse.verseID,
    };
    dispatch(setBible(b));
    dispatch(toggleBiblePopOut(true));
  };

  return (
    <li style={{ float: "left", width: "100%", padding: "10px 0" }}>
      <span
        className="bible-search-header"
        onClick={() => handleVerseHeaderClick(verse)}
      >
        <b>
          {verse.bookName} {verse.chapterID}:{verse.verseID}
        </b>
      </span>
      {getHighlightedText(verse.verseText)}
    </li>
  );
}

const emRegex = /(<em>[a-zA-Z]+<\/em>)/gi;

function getHighlightedText(text: string) {
  const parts = text.split(emRegex);
  return (
    <span>
      {parts.map((part, i) => {
        if (!part.includes("<em>")) {
          return <span key={i}>{part}</span>;
        } else {
          return (
            <span key={i} className="highlighted">
              {part.replace(/<em>/gi, "").replace(/<\/em>/gi, "")}
            </span>
          );
        }
      })}
    </span>
  );
}
