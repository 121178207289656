import { Profile } from "../components";
import { useUserAccount } from "../api/users";
import BarLoader from "react-spinners/BarLoader";

type Props = {
  id: number;
};

export function UserView({ id }: Props) {
  const { data: user } = useUserAccount(id);
  return (
    <div>
      {user !== null && user !== undefined ? (
        <div>
          <div className="profile-pic">
            <Profile imgUrl={user.imgUrl} />
          </div>
          <div>
            <span>First Name:</span> {user.firstName}
          </div>
          <div>
            <span>Last Name:</span> {user.lastName}
          </div>
          <div>
            <span>Email:</span> {user.email}
          </div>
        </div>
      ) : <BarLoader className="loader" />}
    </div>
  );
}
