import { useAppSelector } from "../store";
import { LoginControls } from "./LoginControls";
import { SignUpControls } from "./SignUpControls";

export function Login() {
  const settings = useAppSelector((state: any) => state.settings);

  if (settings.signUpToggle) return <SignUpControls />;
  else return <LoginControls />;
}
