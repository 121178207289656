export function getConfig(organizationId: number, productId: number) {
    const sessionId = localStorage.getItem('session') || '';
    const token = localStorage.getItem('token') || '';
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
        sessionId,
        OrganizationId: organizationId ?? 0,
        ProductId: productId ?? 0,
      }
    }
    return config;
  }