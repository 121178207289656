import React from 'react';

export function Churches() {
  return (
    <div>
      <h1>Churches</h1>
      here you are..
    </div>
  );
}
