import { Route, Routes, Navigate } from "react-router-dom";
import {
  Account,
  Bible,
  BibleSearch,
  Channel,
  Churches,
  EntireBiblePreached,
  Organizations,
  Settings,
  Users,
  VideoHome,
  Video,
} from "../screens";
import { useAppSelector, useAppDispatch } from "../store";
import { setScrollTop } from "../store/slices/settings";

export default function Content() {
  const settings = useAppSelector((state: any) => state.settings);
  const user = useAppSelector((state: any) => state.user);
  const dispatch = useAppDispatch();
  const listenToScroll = (e: any) => {
    const target = e.target as HTMLTextAreaElement;
    dispatch(setScrollTop(target.scrollTop));
  };

  return (
    <div
      onScroll={listenToScroll}
      className={
        "App-content" +
        (settings.mainMenuOpen ? "" : " content-full") +
        (!settings.biblePopOut ? "" : " content-full-bible-popout")
      }
    >
      <Routes>
        <Route
          path="/account"
          element={
            user.token !== "" ? <Account /> : <Navigate replace to="/" />
          }
        />
        <Route path="/bible/search" element={<BibleSearch />} />
        <Route
          path="/bible/:version/:chapter/:chapterId/:verseId"
          element={<Bible />}
        />
        <Route path="/bible/:version/:chapter/:chapterId" element={<Bible />} />
        <Route path="/bible/:version/:chapter" element={<Bible />} />
        <Route path="/bible/:version" element={<Bible />} />
        <Route path="/bible" element={<Bible />} />
        <Route path="/c/:channelName" element={<Channel />} />
        <Route path="/churches" element={<Churches />} />
        <Route path="/entirebiblepreached" element={<EntireBiblePreached />} />
        <Route
          path="/organizations"
          element={
            user.token !== "" && user.organizationAccessGroup ? (
              <Organizations />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="/settings"
          element={
            user.token !== "" ? <Settings /> : <Navigate replace to="/" />
          }
        />
        <Route
          path="/users"
          element={
            user.token !== "" && user.isSuperAdmin ? (
              <Users />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route path="/video/:streamUrlKey" element={<Video />} />
        <Route
          path="/:streamUrlKey"
          element={<Navigate replace to="/video" />}
        />
        <Route path="/video" element={<VideoHome />} />
        <Route path="/" element={<VideoHome />} />
      </Routes>
    </div>
  );
}
