import { useChannel } from "../api/channels";
import { VideoTile } from "../components";
import { useParams } from "react-router-dom";

export function Channel() {
  const { channelName } = useParams<string>();
  var channelNameString = channelName || "";
  const { data } = useChannel(channelNameString) || null;
  return (
    <div>
      {data?.channelStreamName === channelNameString ? (
        <div>
          <h1>{data?.name}</h1>
          <div className="video-tiles-container flex-wrap">
            {data?.streams?.map((stream, i) => (
              <VideoTile key={i} stream={stream} />
            ))}
          </div>
        </div>
      ) : (
        <div className="channel-loading-container">
          <h1>...</h1>
          <div className="channel-loading-background"></div>
          <div className="channel-loading-tabs">
            {[...Array(3)].map((e, i) => (
              <div key={i} className="channel-loading-tab"></div>
            ))}
          </div>
          <div className="channel-loading-video-container">
            {[...Array(20)].map((e, i) => (
              <div key={i} className="channel-loading-video">
                <div className="channel-loading-video-thumbnail"></div>
                <div className="circle"></div>
                <div className="channel-loading-video-details"></div>
                <div className="channel-loading-video-details shorter"></div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
