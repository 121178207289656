import {useQuery} from "react-query";
import axios from "axios";
import {apiBase} from "./shared";
import { BibleVerse, BibleComponentProps } from "../models";

async function bibleLoad(bibleProps: BibleComponentProps) {
  const { data } = await axios.get<BibleVerse[]>(`${apiBase}BibleVerses/Chapter?version=${bibleProps.version}&bookId=${bibleProps.bookId}&chapterId=${bibleProps.chapterId}`);
  return data;
}

export function useBibleLoad(bibleProps: BibleComponentProps) {
  return useQuery(['bibleLoad', bibleProps], () => bibleLoad(bibleProps), {
    enabled: !!bibleProps,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
}
