import { Link } from "react-router-dom";
import { Stream as StreamModel, } from "../models";
import { videoTitle, streamDateTitle, streamViewerCount } from "../utils/videos";
const Placeholder = require("../assets/images/gr-video-placeholder-greywhite.png");

type VideoTileProps = {
  stream: StreamModel
};

export function VideoTile({ stream }: VideoTileProps) {
  const thumbnailSrc = stream.hasThumbnail ? stream.thumbnail : Placeholder;
  var linkUrl = `/video/${stream.streamUrlKey ?? 'unknown'}`;
  return (
    <Link className='video-tile' to={linkUrl}>
      <div className='video-container'>
        <div className="video-img-container">
          <img alt={videoTitle(stream)} src={thumbnailSrc} onError={e => { e.currentTarget.src = Placeholder; }}></img>
        </div>
        <div className="video-title-container">
          <span className="video-title">{videoTitle(stream)}</span>
          <span className="video-title-author">{stream.channelName}</span>
          <span className="video-details">{streamViewerCount(stream.views)} views | {streamDateTitle(stream)}</span>
          {stream && stream.isLive ? <span className="video-title-live">Live</span> : ""}
        </div>
      </div>
    </Link>
  );
}
