const Avatar = require("../assets/images/avatar-icon-1.png");
type Props = {
  imgUrl: string;
}

export function Profile({imgUrl}: Props) {
  return (
   imgUrl ? <img alt="profile-pic" src={imgUrl} /> : <img alt="avatar" src={Avatar} />
  );
}
