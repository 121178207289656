import { useAppSelector } from "../store";
import { User } from "../models";
import { UserView } from "../views";
import { Helmet } from "react-helmet-async";

export function Account() {
  const account: User = useAppSelector((state: any) => state.user);
  return (
    <div>
      <Helmet>
        <title>Account</title>
      </Helmet>
      <h1>Your Account</h1>
      <UserView id={account.id} />
    </div>
  );
}
