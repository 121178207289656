import React from 'react';

export function EntireBiblePreached() {
  return (
    <div>
      <h1>Entire Bible Preached</h1>
      <ul>
        <li>Genesis</li>
        <li>Exodus</li>
        <li>Leviticus</li>
        <li>Numbers</li>
        <li>Deuteronomy</li>
      </ul>
    </div>
  );
}
