import axios from "axios";
import { useQuery } from "react-query";
import { apiBase } from "./shared";
import { Channel, ChannelsResponse } from "../models";

async function getChannels() {
  const { data } = await axios.get<ChannelsResponse>(
    `${apiBase}Channels?channelLimit=10&streamLimit=5`
  );
  return data;
}

export function useChannels() {
  return useQuery(["channels"], () => getChannels(), {
    refetchInterval: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    select: (data) => {
      const channels = data?.channels
        ?.filter(
          (channel: any) =>
            channel?.id !== 7 &&
            channel?.streams !== null &&
            channel?.streams.length > 0
        )
        .sort((a, b) => a.id-b.id);
      var redHotChannelIndex = channels.findIndex((x) => x.id === 6); //-- 6 is the Red Hot Channel
      var redHotChannel = channels[redHotChannelIndex];
      channels.splice(redHotChannelIndex, 1);
      channels.push(redHotChannel);
      return {
        ...data,
        channels,
      };
    },
  });
}

async function getChannelByName(channelName: string) {
  const { data } = await axios.get<Channel>(
    `${apiBase}Channels/${channelName}`
  );
  return data;
}

export function useChannel(channelName: string) {
  return useQuery(["channel"], () => getChannelByName(channelName), {
    refetchInterval: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    select: (data) => {
      return {
        ...data,
      };
    },
  });
}
