import { useContext, useState, useCallback, useRef } from "react";
import { useAppSelector } from "../store";
import { AbilityContext } from "./Can";
import { userRegister } from "../api/users";
import { useAppDispatch } from "../store";
import { swapUser } from "../store/slices/users";
import {
  toggleLoginMenu,
  toggleSignUp
} from "../store/slices/settings";
import { updateAbility } from "../utils/ability";
import { User } from "../models";
import BarLoader from "react-spinners/BarLoader";
import ReCAPTCHA from "react-google-recaptcha";

export function SignUpControls() {
  const settings = useAppSelector((state: any) => state.settings);

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [recaptcha, setRecaptcha] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const ability = useContext(AbilityContext);
  const dispatch = useAppDispatch();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const checkForErrors = () => {
    setErrorMessage("");
    if (!recaptcha) {
      setErrorMessage("You a robot? You gotta click the button first.");
      return true;
    }

    if (!firstName) {
      setErrorMessage("You must put in a first name.");
      return true;
    }

    if (!lastName) {
      setErrorMessage("You must put in a last name.");
      return true;
    }

    if (!username) {
      setErrorMessage("You must put in a username.");
      return true;
    }

    if (!email) {
      setErrorMessage("You must put in a email.");
      return true;
    }

    if (!password) {
      setErrorMessage("You must put in a password.");
      return true;
    }

    if (!passwordConfirm) {
      setErrorMessage("You must confirm your password.");
      return true;
    }

    if (password !== passwordConfirm) {
      setErrorMessage("Passwords Must Match.");
      return true;
    }

    return false;
  };

  const handleClick = async () => {
    if (checkForErrors()) return false;
    setIsLoading(true);
    const data: User = await userRegister({
      username,
      password,
      firstName,
      lastName,
      email,
    });
    if (data) {
      updateAbility(ability, data);
      dispatch(swapUser(data));
      dispatch(toggleSignUp);
    } else {
      //-- add error handling
    }

    setIsLoading(false);
    dispatch(toggleLoginMenu());
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  const toggleSignUpClick = useCallback(() => {
    dispatch(toggleSignUp());
  }, [dispatch]);

  const onCaptcha = (value: any) => {
    setRecaptcha(value);
    return false;
  };

  return (
    <div className="login-wrapper">
      <h1>Sign Up</h1>
      <form onSubmit={handleClick}>
        <label>
          <p>First Name</p>
          <input
            type="text"
            autoComplete="Off"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            onKeyDown={(e) => onKeyDown(e)}
          />
        </label>
        <label>
          <p>Last Name</p>
          <input
            type="text"
            autoComplete="Off"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onKeyDown={(e) => onKeyDown(e)}
          />
        </label>
        <label>
          <p>Username</p>
          <input
            type="text"
            autoComplete="Off"
            value={username}
            onChange={(e) => setUserName(e.target.value)}
            onKeyDown={(e) => onKeyDown(e)}
          />
        </label>
        <label>
          <p>Email</p>
          <input
            type="text"
            autoComplete="Off"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => onKeyDown(e)}
          />
        </label>
        <label>
          <p>Password</p>
          <input
            type="password"
            autoComplete="Off"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => onKeyDown(e)}
          />
        </label>
        <label>
          <p>Confirm Password</p>
          <input
            type="password"
            autoComplete="Off"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            onKeyDown={(e) => onKeyDown(e)}
          />
        </label>
        <br />
        <br />
        {settings.loginMenuOpen && settings.signUpToggle ? (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
            onChange={onCaptcha}
          />
        ) : null}
        <br />
        <div className="flex-box flex-wrap">
          <div className="button" onClick={handleClick}>
            Submit
          </div>
          <div className="button-link" onClick={toggleSignUpClick}>
            Cancel
          </div>
          {isLoading ? (
            <div className="loader-container">
              <BarLoader className="loader" />
            </div>
          ) : null}
        </div>
        <br />
        {errorMessage ? (
          <div className="flex-box flex-wrap error-message">{errorMessage}</div>
        ) : null}
      </form>
    </div>
  );
}
