import { useContext } from 'react';
import { AbilityContext } from './Can';
import { useAppDispatch } from "../store";
import { clearUser } from '../store/slices/users';
import { toggleProfileMenu } from '../store/slices/settings';
import { updateAbility } from '../utils/ability';
import { Link } from 'react-router-dom';
import { User } from "../models";
import { GoOrganization, GoShield, GoSettings, GoSignOut } from 'react-icons/go';
import { IoBusiness } from "react-icons/io5";

type UserProps = {
  user: User
}

export function ProfileMenu({user}: UserProps) {
  const ability = useContext(AbilityContext);
  const dispatch = useAppDispatch();

  const handleClick = async () => {
    updateAbility(ability, undefined);
    dispatch(clearUser());
    dispatch(toggleProfileMenu());
  }

  return (
    <div className="profile-menu-wrapper">
      <Link to="/account" onClick={() => dispatch(toggleProfileMenu(false))}><GoShield size={22} /><span className='profile-menu-title'>Account</span></Link>
      {user?.organizationAccessGroup ? 
        <Link to="/organizations" onClick={() => dispatch(toggleProfileMenu(false))}><IoBusiness size={22} /><span className='profile-menu-title'>Organizations</span></Link> : null}
        {user?.isSuperAdmin ? 
        <Link to="/users" onClick={() => dispatch(toggleProfileMenu(false))}><GoOrganization size={22} /><span className='profile-menu-title'>Users</span></Link> : null}
      <Link to="/settings" onClick={() => dispatch(toggleProfileMenu(false))}><GoSettings size={22} /><span className='profile-menu-title'>Settings</span></Link>
      <div className="logout" onClick={handleClick}><GoSignOut size={22} /><span className='profile-menu-title'>Logout</span></div>
    </div >
  )
}