import React from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { BibleComponent } from "../components";

export function Bible() {
  const { version, chapter, chapterId : chapterIdString, verseId : verseIdString, bookId : bookIdString} = useParams();
  const chapterId =  parseInt(chapterIdString ?? '');
  const verseId =  parseInt(verseIdString ?? '');
  const bookId =  parseInt(bookIdString ?? '');
  const commonProps = {version, chapter, chapterId, verseId, bookId };
  return (
    <div>
      <Helmet>
        <title>Bible</title>
      </Helmet>
      <h1>Bible</h1>
      <BibleComponent {...commonProps} />
    </div>
  );
}
