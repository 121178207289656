import {useQuery} from "react-query";
import axios from "axios";
import {apiBase} from "./shared";
import { BibleVerseResponse } from "../models";

async function bibleSearch(searchText: string) {
  const text = encodeURI(searchText);
  const { data } = await axios.get<BibleVerseResponse>(`${apiBase}BibleVerses?version=kjv&searchText=${text}&highlighting=true&limit=50000`);
  return data;
}

export function useBibleSearch(searchText: string) {
  return useQuery(['bibleSearch', searchText], () => bibleSearch(searchText), {
    enabled: !!searchText,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
}
