import { useQuery } from "react-query";
import axios from "axios";
import { apiBase } from "./shared";
import { User, UserCredentials, UserRegistration } from "../models";
import { getConfig } from "./config";

async function users() {
  const { data } = await axios.get<User[]>(
    `${apiBase}Users`, getConfig(0, 0)
  );
  return data;
}

export async function userLogin(userCredentials: UserCredentials) {
  const { data } = await axios.post(
    `${apiBase}Users/Authenticate`, userCredentials
  );
  return data;
}

export async function userRegister(userRegistration: UserRegistration) {
  const { data } = await axios.post(
    `${apiBase}Users/Register`, userRegistration
  );
  return data;
}

async function userSearch(userId: number) {
  const { data } = await axios.get<User>(`${apiBase}Users`);
  return data;
}

export async function userAccount(userId: number) {
  const { data } = await axios.get<User>(`${apiBase}Users/${userId}`, getConfig(0, 0));
  return data;
}

export function useGetAllUsers() {
  return useQuery([], () => users(), {
    enabled: true,
    refetchIntervalInBackground: false,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}

export function useUserAccount(userId: number) {
  return useQuery(['user', userId], () => userAccount(userId), {
    enabled: !!userId,
    refetchIntervalInBackground: false,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}

export function useUserSearch(userId: number) {
  return useQuery(['userSearch', userId], () => userSearch(userId), {
    enabled: !!userId,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
}
