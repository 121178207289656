import { Channel as ChannelModel, Stream as StreamModel } from "../models";
import { VideoTile } from "../components";
import { useChannels } from "../api/channels";
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

export function VideoHome() {
  const { data } = useChannels();

  var activeLivestreams: StreamModel[] = [];
  data?.channels?.forEach(c => {
    c.streams.forEach(s => {
      if (s.isLive) activeLivestreams.push(s);
    })
  });
  return (
    <div>
      <Helmet>
        <title>GodResource - Video</title>
      </Helmet>
      <h1>Video</h1>
      {data
        && activeLivestreams.length > 0 ?
        <div className="channel-container" >
          <span className="channel-link live-link">
            <h3>Live Right Now</h3>
          </span>
          <div className="video-tiles-container">
            {activeLivestreams.map((stream, i) => (
              <VideoTile key={i} stream={stream} />)
            )}
          </div>
        </div> : null}
      {data?.channels ? data.channels.map((channel, i) =>
        <Channel key={i} channel={channel} />
      ) : null}
    </div>
  );
}

function Channel({ channel }: { channel: ChannelModel; }) {
  const hasStreams = channel.streams != null && channel.streams.length > 0;
  const channelUrl = `/c/${channel.channelStreamName}`;
  return (
    <div className="channel-container" >
      <Link className="channel-link" to={channelUrl}>
        <h3>{channel.name}</h3>
      </Link>
      {/* {channel.streams.length > 4 ? <div className="video-arrow-right"></div> : null} */}
      <div className="video-tiles-container">
        {channel.streams?.map((stream, i) => (
          <VideoTile key={i} stream={stream} />)
        )}
        {!hasStreams ? <h4>No streams</h4> : null}
      </div>
    </div>
  );
}
