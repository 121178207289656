import { AbilityBuilder, Ability, AbilityClass } from '@casl/ability';
import { User } from '../models'; // application specific interfaces

type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete';
type Subjects = 'Video' | Video | 'all' | 'Blog' | Blog;

export type AppAbility = Ability<[Actions, Subjects]>;
// eslint-disable-next-line
export const AppAbility = Ability as AbilityClass<AppAbility>;

export interface Video {
  type: 'Video'
  id: number
  organizationId: number
  productId: number
}
export interface Blog {
  type: 'Blog'
  id: number
  organizationId: number
  productId: number
}

export function updateAbility(ability: AppAbility, user: User | undefined) {
  if (user !== undefined) {
    var rules = defineRulesFor(user);
    ability.update(rules);
  }
}

export function defineRulesFor(user: User | undefined) {
  const { can, rules } = new AbilityBuilder(AppAbility);

  if (user === undefined) {
    return rules;
  }
  if(user.organizationAccessGroup === undefined || null) {
    return rules;
  }

  if (user?.isSuperAdmin) {
    can('manage', 'all');
  }
  
  user.organizationAccessGroup != null && user.organizationAccessGroup.forEach(group => {
    group?.productAccessGroup.forEach(product => {
      if (product.role === 'Admin') {
        can('manage', 'all', { type: product.type, productId: { $in: product.productIds }, organizationId: group.organizationId });
      }
      else if (product.role === 'Moderator') {
        can('manage', 'Video', { type: product.type, productId: { $in: product.productIds }, organizationId: group.organizationId });
        can('manage', 'Blog', { type: product.type, productId: { $in: product.productIds }, organizationId: group.organizationId });
      }
      else {
        if (product.type === 'Video') {
          can(['read', 'create'], 'Video', { productId: { $in: product.productIds }, organizationId: group.organizationId });
          can(['update', 'delete'], 'Video', { productId: { $in: product.productIds }, organizationId: group.organizationId });
        }
        if (product.type === 'Blog') {
          can(['read', 'create'], 'Blog', { productId: { $in: product.productIds }, organizationId: group.organizationId });
          can(['update', 'delete'], 'Blog', { productId: { $in: product.productIds }, organizationId: group.organizationId });
        }
      }
    })
  })

  return rules;
}

export function buildAbilityFor(user: User | undefined): AppAbility {
  return new AppAbility(defineRulesFor(user), {
    detectSubjectType: (object) => object!.type
  });
}