import { useAppSelector, useAppDispatch } from "../store";
import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import { toggleTheme } from "../store/slices/settings";
import { useUserAccount } from "../api/users";
import { User } from "../models";
import { UserView } from "../views";
import { Helmet } from "react-helmet-async";
import { Profile } from "../components";


export function Settings() {
  const dispatch = useAppDispatch();
  const { settings } = useAppSelector((state: any) => state);
  
  //const { data: user } = useUserAccount(account.id);

  const handleChange = async () => {
    dispatch(toggleTheme());
  };

  return (
    <div>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <h1>Settings</h1>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch checked={settings.isDarkTheme} onChange={handleChange} />
          }
          label="Dark Theme"
        />
      </FormGroup>
    </div>
  );
}
