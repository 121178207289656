import ReactPlayer from "react-player/file";
import { useMemo } from "react";
const Placeholder = require("../assets/images/gr-video-placeholder-greywhite.png");

type ReactPlayerWrapperProps = {
  url: string;
};

export function ReactPlayerWrapper({ url }: ReactPlayerWrapperProps) {
  const imageClassName = useMemo(() => {
    return `video-player-placeholder ${url ? 'hide' : ''}`;
  }, [url]);

  return (
    <div className='player-wrapper'>
      {url ? <ReactPlayer
        url={url}
        controls={true}
        className='react-player'
        width='100%'
        height='100%'
        onError={(err, ...rest: any[]) => {
          console.log(err, rest);
        }}
      /> : ''}
      <img className={imageClassName} src={Placeholder} alt='God Resource video player' />
    </div>
  );
}
